import CountryList from "countries-list";
import {
    Select,
} from 'antd';

CountryList.languagesAllSelect = []
for (var key in CountryList.languagesAll) {
    const lang = CountryList.languagesAll[key]
    lang.code = key
    CountryList.languagesAllSelect.push({
        value: lang.code,
        label: lang.native
    })
}

CountryList.countriesSelect = []
for (var key in CountryList.countries) {
    const item = CountryList.countries[key]
    item.code = key
    CountryList.countriesSelect.push({
        value: item.code,
        label: item.name
    })
}

const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  

export function UtilsInputLang(props) {
    return (
        <Select
            {...props}
            // defaultValue={navigator.language.split("-")[0]}
            options={CountryList.languagesAllSelect}
            filterOption={filterOption}
            showSearch
            filterSort={(country1, country2) => (country1.label?.toUpperCase() < country2.label?.toUpperCase()) ? -1 : 1}
        />
    )
}

export function UtilsInputCountry(props) {
    return (
        <Select
            {...props}
            // defaultValue={navigator.language.split("-")[0]}
            options={CountryList.countriesSelect}
            filterOption={filterOption}
            showSearch
            filterSort={(country1, country2) => (country1.label?.toUpperCase() < country2.label?.toUpperCase()) ? -1 : 1}
        />
    )
}

export function UtilsHidePart(apiKey, startVisibleChars, endVisibleChars, maskChar = '*', noRepeat = false) {
    const apiKeyLength = apiKey.length;
    if (apiKeyLength <= startVisibleChars + endVisibleChars)
        return apiKey;
    if (noRepeat === false) {
        const visiblePart = apiKey.slice(0, startVisibleChars) + maskChar.repeat(apiKeyLength - startVisibleChars - endVisibleChars) + apiKey.slice(apiKeyLength - endVisibleChars);
        return visiblePart;
    }
    const visiblePart = apiKey.slice(0, startVisibleChars) + maskChar + apiKey.slice(apiKeyLength - endVisibleChars);
    return visiblePart;
}

export function UtilsConvertTime(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24)) % 30;
    const months = Math.floor(milliseconds / (1000 * 60 * 60 * 24 * 30));

    const result = [];
    if (months > 0) {
        result.push(months + (months === 1 ? ' month' : ' months'));
    }
    if (days > 0) {
        result.push(days + (days === 1 ? ' day' : ' days'));
    }
    if (hours > 0) {
        result.push(hours + (hours === 1 ? ' hour' : ' hours'));
    }
    if (minutes > 0) {
        result.push(minutes + (minutes === 1 ? ' minute' : ' minutes'));
    }
    if (seconds > 0) {
        result.push(seconds + (seconds === 1 ? ' second' : ' seconds'));
    }

    return result.join(', ');
}

export function UtilsCleanEmptyStrings(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(UtilsCleanEmptyStrings);
    }

    const cleanedObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value === null) continue
            if (typeof value === 'string' && value.length === 0) continue

            cleanedObj[key] = UtilsCleanEmptyStrings(value);
        }
    }

    return cleanedObj;
}

export function getCoverageColor(percentil) {
    // Clamp entre 0 et 100 pour éviter les erreurs
    percentil = Math.max(0, Math.min(100, percentil)) / 100;

    // Points de couleur définissant le dégradé
    const colors = [
        { percent: 0,   color: [255, 36,  0]   }, // Rouge (#FF2400)
        // { percent: 0.33, color: [0,   127, 255] }, // Bleu  (#007FFF)
        { percent: 0.66, color: [255, 215, 0]   }, // Jaune (#FFD700)
        { percent: 1,   color: [50,  205, 50]  }  // Vert  (#32CD32)
    ];

    // Trouver les deux couleurs entre lesquelles interpoler
    let start, end;
    for (let i = 0; i < colors.length - 1; i++) {
        if (percentil >= colors[i].percent && percentil <= colors[i + 1].percent) {
            start = colors[i];
            end = colors[i + 1];
            break;
        }
    }

    // Calcul de la progression entre les deux couleurs
    const range = end.percent - start.percent;
    const progress = (percentil - start.percent) / range;

    // Interpolation linéaire RGB
    const r = Math.round(start.color[0] + progress * (end.color[0] - start.color[0]));
    const g = Math.round(start.color[1] + progress * (end.color[1] - start.color[1]));
    const b = Math.round(start.color[2] + progress * (end.color[2] - start.color[2]));

    // Conversion en format hexadécimal
    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()}`;
}