import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import {
    Table,
    Button,
    Space,
    Modal,
    Tag,
    Form,
    Input,
    Select,
    Popconfirm,
    notification, Spin
} from 'antd'

import {
    SettingOutlined,
    DeleteOutlined
} from '@ant-design/icons'

import { DiventryBlock } from '../../../../Templates/Layout'

export const rulesColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (text, record, index) => {
            if (record.mode === "network") {
                return (<Tag key={`type-${record.id}`}>Network</Tag>)
            }
            else {
                if (record.type === "search") {
                    return (<Tag key={`type-${record.id}`}>Dichotomy</Tag>)
                }
                else if (record.type === "regex") {
                    return (<Tag key={`type-${record.id}`}>Regex</Tag>)
                }
            }
            return ("")
        }
    },
    {
        title: 'Rules',
        dataIndex: 'rule',
        key: 'rule',
        // align: "right",
        render: (text, record, index) => {
            if (record.type === "search" && record.search) {
                return (record.search.map((item) => {
                    return (<Tag key={`search-${record.id}-${item}`}>{item}</Tag>)
                }))
            }
            else if (record.type === "regex" && record.regex) {
                return (record.regex.map((item) => {
                    return (<Tag key={`regex-${record.id}-${item}`}>{item}</Tag>)
                }))
            }
            else if (record.mode === "network" && record.network) {
                return (<Tag key={`regex-${record.id}-${record.network}`}>{record.network}</Tag>)
            }

            else
                return ("")
        }
    },
    {
        title: '',
        dataIndex: 'buttons',
        key: 'buttons',
        align: "right",
    }
]


export function RulesPositive({ kernel }) {
    const [loaded, setLoaded] = useState(true);

    // main document
    const [table, setTable] = useState([]);
    const [testResult, setTestResult] = useState(null);

    // form related
    const [isModalForm, setIsModalForm] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [theForm] = Form.useForm();
    const mode = Form.useWatch('mode', theForm);
    const type = Form.useWatch('type', theForm);
    const regex = Form.useWatch('regex', theForm);
    const search = Form.useWatch('search', theForm);
    const network = Form.useWatch('network', theForm);

    const { id } = useParams()

    // async load
    useEffect(() => {
        async function fetch() {
            const ret = await kernel.api.get(`/cyberintel/rules/list?instanceId=${id}`);
            if (!ret || ret.error) {
                notification.error({
                    message: 'Error creating rule',
                    description: ret.error
                });
                return;
            }

            const newTable = [];
            if (Array.isArray(ret.data)) {
                for (let data of ret.data) {
                    data.key = data.id;

                    // process button
                    data.buttons = <Space.Compact size="small">
                        <Popconfirm
                            placement="bottom"
                            title="Delete this rule"
                            description="The rule will be permanently deleted"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={async () => {
                                const ret = await kernel.api.post(
                                    `/cyberintel/rules/remove`,
                                    {
                                        id: data.key,
                                        instanceId: id
                                    }
                                );
                                if (!ret || ret.error) {
                                    notification.error({
                                        message: 'Error deleting the rule',
                                        description: ret.error
                                    });
                                    return;
                                }

                                notification.success({
                                    message: 'Rule has been deleted'
                                });

                                setLoaded(!loaded);
                            }}
                        >
                            <Button><DeleteOutlined /></Button>
                        </Popconfirm>


                        <Button
                            onClick={async () => {
                                setIsEdit(data.key);
                                theForm.resetFields();
                                theForm.setFieldsValue(data);
                                setTestResult(null);
                                setIsModalForm(true);
                            }}
                        ><SettingOutlined /></Button>
                    </Space.Compact>;

                    newTable.push(data);
                }
                setTable(newTable);
            }


        }
        fetch();
    }, [loaded, id]);

    // Create handler
    const handleModalOk = async () => {
        const data = {
            ...theForm.getFieldsValue(),
            instanceId: id
        };
        if (isEdit) data.id = isEdit;

        setIsLoading(true);
        const ret = isEdit ?
            await kernel.api.post(`/cyberintel/rules/update`, data)
            :
            await kernel.api.post(`/cyberintel/rules/create`, data);
        setIsLoading(false);
        if (!ret || ret.error) {
            notification.error({
                message: isEdit ? 'Error updating rule' : 'Error creating rule',
                description: ret.error
            });
            return;
        }
        notification.success({
            message: isEdit ? 'Rule updated' : 'New rule created'
        });
        setIsEdit(null);
        setIsModalForm(false);
        setLoaded(!loaded);
    };
    const handleModalCancel = () => {
        setIsEdit(null);
        setIsModalForm(false);
    };

    const handleModalTest = async () => {
        var tester = null;
        const data = {};
        if (type === "regex") tester = data.regex = regex;
        else if (type === "search") tester = data.search = search;
        else if (mode === "network") tester = data.network = network;
        else {
            notification.warning({
                message: 'Please select a searching mode'
            });
            return;
        }
        if (!tester) {
            notification.warning({
                message: 'Please enter searching patterns'
            });
            return;
        }

        if (tester.length === 0) {
            notification.warning({
                message: 'Please enter at least one searching pattern'
            });
            return;
        }

        setIsLoading(true);
        const raw = await kernel.api.post(`/cyberintel/global/test-rule`, data);
        setIsLoading(false);
        if (!raw) {
            notification.warning({
                message: 'Please try again later'
            });

            return;
        }
        if (raw.error) {
            notification.warning({
                message: 'Error testing the rule',
                description: raw.error
            });
            return;
        }
        setTestResult(raw.data);
    };

    return (
        <>
            <Modal
                title={isEdit ? "Update a rule" : "Add a rule"}
                open={isModalForm}

                onCancel={handleModalCancel}

                // loading={true}
                footer={[
                    <Button key="test" onClick={handleModalTest} disabled={isLoading}>Test the rule</Button>,
                    <Button key="ok" onClick={handleModalOk} type="primary" disabled={isLoading}>
                        {isEdit ? "Update the rule" : "Add the rule"}
                    </Button>,
                    <Button key="cancel" onClick={handleModalCancel}>Cancel</Button>
                ]}
            >
                <Spin spinning={isLoading} tip="Please wait few seconds">
                    <Form
                        form={theForm}
                    >
                        <Form.Item label="Rule name" name="name">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Mode" name="mode">
                            <Select
                                style={{ width: '100%' }}
                                options={[
                                    { value: "domain", label: "Domain searching" },
                                    { value: "network", label: "Network searching" },
                                ]}
                                disabled={isEdit ? true : false} />
                        </Form.Item>

                        {mode === "domain" ?
                            <>
                                <Form.Item label="Searching type" name="type">
                                    <Select
                                        style={{ width: '100%' }}
                                        options={[
                                            { value: "regex", label: "Regex based search" },
                                            { value: "search", label: "Dichotomy based search" },
                                        ]}
                                        disabled={isEdit ? true : false} />
                                </Form.Item>

                                {type === "regex" ?
                                    <Form.Item label="Regex" name="regex">
                                        <Select
                                            mode="tags"
                                            style={{ width: '100%' }} />
                                    </Form.Item>
                                    :
                                    <Form.Item label="Dichotomy" name="search">
                                        <Select
                                            mode="tags"
                                            style={{ width: '100%' }} />
                                    </Form.Item>}
                            </>
                            : null}


                        {mode === "network" ?
                            <Form.Item label="IP or network" name="network">
                                <Input />
                            </Form.Item>
                            : null}


                        <p style={{ fontStyle: "italic" }}>
                            To activate this rule, all the above search expressions must match.
                            The number of returned results is limited to 10,000. You can test your rule.
                        </p>

                        {testResult ?
                            <p style={{ fontWeight: 600 }}>
                                The search test has been validated with <u>{testResult.results}</u> results returned.
                            </p>
                            : null}
                    </Form>
                </Spin>
            </Modal>

            <DiventryBlock bottom={true}>
                <Space.Compact>
                    <Button type="primary" onClick={() => {
                        setIsEdit(null);
                        theForm.resetFields();
                        theForm.setFieldsValue({ mode: "domain", type: "search" });
                        setTestResult(null);
                        setIsModalForm(true);
                    }}>Add rule</Button>
                </Space.Compact>
            </DiventryBlock>

            <DiventryBlock>
                <Table dataSource={table} columns={rulesColumns} />
            </DiventryBlock>
        </>
    );
}
