import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Popover,
    Descriptions,
    Spin,
    Table
} from 'antd';
import { ThreatDBTag } from './Tag';

const _cache = {}

export function ThreatDBIOC({ kernel, value, children }) {

    const [clicked, setClicked] = useState(false)
    const [hovered, setHovered] = useState(false)
    const [hoverContent, setHoverContent] = useState(null)

    const hide = () => {
        setClicked(false);
        setHovered(false);
    };

    const handleHoverChange = async (open) => {
        setHovered(open)
        setClicked(false)

        var data = _cache[value]
        if (data) {
            if (Date.now() - data.time > 10 * 60 * 1000) {
                data = null
                delete _cache[value]
            }
            else {
                data = data.data
            }

        }

        if (!data) {
            const ret = await kernel.api.get(`/threatdb/info/domain?query=${value}`);
            if (ret.error) {
                hide()
                return
            }
            data = ret.data
            _cache[value] = {
                time: Date.now(),
                data: ret.data
            }
        }

        setHoverContent(
            <div style={{
                width: 600,
            }}>
                <Descriptions
                    size="small"
                    column={2}
                    layout='vertical'
                >
                    {/* <Descriptions.Item label="Type">{data.type}</Descriptions.Item> */}
                    {data.type === "domain" ?
                        <Descriptions.Item label="Domain" span={2}><strong>{data.title}</strong></Descriptions.Item>
                        : null}

                    {data.master ?
                        <Descriptions.Item label="Master" span={2}>
                            {Object.keys(data.master.tags).map((tag) => {
                                return (<ThreatDBTag key={`${data.key}-${tag}`} kernel={kernel} tag={tag} />)
                            })}
                        </Descriptions.Item>
                        : null}

                    {data.lists && data.lists.length > 0 ?
                        <Descriptions.Item label="Lists" span={2}>
                            <Table
                                size="small"
                                style={{
                                    width: 500,
                                    fontSize: "0.8em"
                                }}
                                dataSource={data.lists}
                                columns={[
                                    {
                                        title: 'Name',
                                        dataIndex: 'name',
                                        key: 'name',
                                        render: (text, record, index) => {
                                            return (<div style={{ fontSize: "0.9em" }}>{text}</div>)
                                        }
                                    },
                                    {
                                        title: 'Tags',
                                        dataIndex: 'tags',
                                        key: 'tags',
                                        render: (text, record, index) => {
                                            if (!record?.result?.tags)
                                                return ("-")
                                            const ret = Object.keys(record.result.tags).map((tag) =>
                                                <ThreatDBTag key={`${record.id}-${tag}`} kernel={kernel} tag={tag} />
                                            )
                                            return (<div style={{ fontSize: "0.9em" }}>{ret}</div>)
                                        }
                                    },
                                ]}
                                // loading={loading}
                                pagination={{
                                    defaultPageSize: 5,
                                    pageSizeOptions: [5, 10, 20]
                                }}
                            />
                        </Descriptions.Item>
                        : null}
                </Descriptions>
            </div>
        )

    }

    const handleClickChange = (open) => {
        setHovered(false)
        setClicked(open)
    }

    return (
        <>
            <Popover
                content={<Spin tip="Searching IOC" spinning={hoverContent ? false : true}>
                    <div style={{ minHeight: 50, width: 500 }}>
                        {hoverContent}
                    </div>
                </Spin>}
                title="ThreatDB.IOC"
                trigger="hover"
                open={hovered}
                onOpenChange={handleHoverChange}
            >
                {/* <Popover
                    content={
                        <div>
                            {clickContent}
                            <a onClick={hide}>Close</a>
                        </div>
                    }
                    title="Click title"
                    trigger="click"
                    open={clicked}
                    onOpenChange={handleClickChange}
                > */}
                <div style={{
                    cursor: "pointer",
                    fontWeight: 600
                }}>
                    {children ? children : value}
                </div>

                {/* </Popover> */}
            </Popover>
        </>
    )
}
