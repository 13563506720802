
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom"
import { Content } from '../../../../Templates'
import {
    Table,
    Button,
    Tag,
    Tabs,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber
} from 'antd'
import { CaretLeftOutlined } from '@ant-design/icons'



import { DiventryMenu } from '../../../../Templates/Menu'
import {
    DiventryLayoutCyberIntel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock,
    DiventryTitle
} from '../../../../Templates/Layout'

export function DangerZone({ kernel }) {
    const [loaded, setLoaded] = useState(true)

    const [info, setInfo] = useState({})

    // for navigation
    const navigate = useNavigate()
    const { id } = useParams()

    // async load
    useEffect(() => {
        async function fetch() {
            const raw = await kernel.api.get(`/cyberintel/instances/get?id=${id}`)
            if (raw.error || !raw.data) return (navigate("/cyberintel/instances"))
            setInfo(raw.data)
        }
        fetch()
    }, [loaded])

    return (
        <DiventryBlock>
            <h1>Danger zone</h1>
            <Popconfirm
                placement="bottom"
                title="Delete this instance"
                description="The instance will be permanently deleted"
                okText="Yes"
                cancelText="No"
                onConfirm={async () => {
                    const ret = await kernel.api.post(
                        `/cyberintel/instances/remove`,
                        { id: info.id }
                    )
                    if (ret.error) {
                        notification.error({
                            message: 'Error deleting key'
                        })
                        return
                    }

                    notification.success({
                        message: 'Instance has been deleted'
                    })

                    navigate('/cyberintel/instances')
                }}
            >
                <Button type="primary" danger>Delete</Button>
            </Popconfirm>

        </DiventryBlock>

    );
}