
import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"

import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber,
    ColorPicker,
    Checkbox
} from 'antd'

import {
    SettingOutlined,
    DeleteOutlined
} from '@ant-design/icons'


import { DiventryMenu } from '../../Templates/Menu'
import {
    DiventryLayoutThreatDB,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../Templates/Layout'

export const Responses = {
    none: "Do nothing",
    notice: "Administration Notice",
    investigation: "Investigation Required",
}

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
        render: (text, record, index) => {
            if (record.color)
                return (<Tag>{text}</Tag>)
            return (<Tag>{text}</Tag>)
        }
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Servers',
        dataIndex: 'servers',
        key: 'servers',
        render: (text, record, index) => {
            if (!record.servers)
                record.servers = []
            return (<>
                {record.servers.map((ip) => {
                    return (<Tag>{ip}</Tag>)
                })}
            </>)
        }
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        render: (text, record, index) => {
            if (record.isActive === true)
                return ("Active")
            return ("Disabled")
        }
    },
    {
        title: '',
        dataIndex: 'buttons',
        key: 'buttons',
        align: "right"
    },
]

export function Resolvers({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [table, setTable] = useState([])

    // for list creation
    const [isModalCreate, setIsModalCreate] = useState(false)
    const [modalMode, setModalMode] = useState(null)
    const [servers, setServers] = useState([])
    const [createForm] = Form.useForm()

    const navigate = useNavigate()

    useEffect(() => {
        async function fetch() {
            const newTable = []
            const raw = await kernel.api.get('/cyberintel/resolvers/list')
            if (!raw || raw.error) {
                notification.error({
                    message: `Fetch error`,
                    description: raw.error
                })
                return
            }

            const resList = raw.data?.sort((a, b) => a.order - b.order);

            for (let data of resList) {

                data.buttons = <Space.Compact size="small">
                    <Popconfirm
                        placement="left"
                        title="Delete this resolver"
                        description="The entry will be permanently deleted"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={async () => {
                            const ret = await kernel.api.post(
                                `/cyberintel/resolvers/remove`,
                                { id: data.id }
                            )
                            if (ret.error) {
                                notification.error({
                                    message: 'Error deleting key',
                                    description: ret.error
                                })
                                return
                            }
                            notification.success({
                                message: 'Resolver was deleted'
                            })
                            setLoaded(!loaded)
                        }}
                    >
                        <Button><DeleteOutlined /></Button>
                    </Popconfirm>
                    <Button onClick={() => {
                        setModalMode(data.id)
                        setServers(data.servers || [])
                        createForm.resetFields()
                        createForm.setFieldsValue(data)
                        setIsModalCreate(true)
                    }}><SettingOutlined /></Button>
                </Space.Compact>

                newTable.push(data)
            }
            setTable(newTable)
        }
        fetch()
    }, [loaded])

    // Create handler
    const handleCreateOk = async () => {
        const data = {
            ...createForm.getFieldsValue(),
            servers
        }

        if (modalMode === null) {
            const ret = await kernel.api.post(`/cyberintel/resolvers/create`, data)
            if (!ret || ret.error) {
                notification.error({
                    message: `Creation error: ${ret.error}`,
                    description: Array.isArray(ret.fields) ? Object.values(ret.fields).map((el) => <div key={el}>{el}</div>) : ret.error
                })
                return
            }
            notification.success({
                message: 'New resolver added'
            })
        }
        else {
            data.id = modalMode
            const ret = await kernel.api.post(`/cyberintel/resolvers/update`, data)
            if (ret.error) {
                notification.error({
                    message: `Creation error: ${ret.error}`,
                    description: Array.isArray(ret.fields) ? Object.values(ret.fields).map((el) => <div key={el}>{el}</div>) : null
                })
                return
            }
            notification.success({
                message: 'Resolver has been updated'
            })
        }

        setIsModalCreate(false)
        setLoaded(!loaded)
    }
    const handleCreateCancel = () => {
        setIsModalCreate(false)
    }

    return (
        <DiventryLayoutThreatDB>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="CyberIntel Resolvers"
                description="Resolvers used by CyberIntel"
            />
            <DiventryLayoutContent>

                <Modal
                    title={modalMode === null ? "Add a CyberIntel Resolver" : "Updating CyberIntel Resolver"}
                    open={isModalCreate}
                    onOk={handleCreateOk}
                    onCancel={handleCreateCancel}
                >
                    <Form
                        // layout="vertical"
                        form={createForm}
                    >
                        <Form.Item label="Key for printing" name="key">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Name of the resolver" name="name">
                            <Input />
                        </Form.Item>

                        <Form.Item name="isActive" valuePropName="checked">
                            <Checkbox>
                                The resolver is active
                            </Checkbox>
                        </Form.Item>
                    </Form>

                    <div>
                        <div>
                            <Button onClick={() => {
                                setServers([...servers, ""])
                            }}>Add server</Button>
                        </div>
                        {servers.map((record, index) => {
                            return (<div key={index}>
                                <Space.Compact style={{ width: "100%" }}>
                                    <Input value={record} onChange={(value) => {
                                        servers[index] = value.target.value
                                        setServers([...servers])
                                    }} />
                                    <Button onClick={() => {
                                        const copy = [...servers]
                                        copy.splice(index, 1)
                                        setServers(copy)
                                    }}>Delete</Button>
                                </Space.Compact>
                            </div>)
                        })}

                    </div>
                </Modal>

                <p>
                    <Space>
                        <Button type="primary" onClick={() => {
                            createForm.resetFields()
                            setServers([""])
                            setModalMode(null)
                            setIsModalCreate(true)
                        }}>
                            Add Resolver
                        </Button>

                    </Space>

                </p>
                <DiventryBlock>
                    <Table dataSource={table} columns={columns} />
                </DiventryBlock>
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />
        </DiventryLayoutThreatDB>
    );
}