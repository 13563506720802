
import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Content } from '../../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    Empty,
    Spin,
    InputNumber
} from 'antd'

import { DiventryMenu } from '../../../Templates/Menu'
import {
    DiventryLayoutCyberIntel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../../Templates/Layout'

import { ThreatDBTag } from '../../../Templates/Tag'
import { ThreatDBIOC } from '../../../Templates/ThreatDBIOC'

import { getCoverageColor } from '../../../Templates/Utils'

const domainColumns = [
    {
        title: 'Domain Name',
        dataIndex: 'domainShow',
        key: 'domainShow',
    },

    {
        title: 'First detection',
        dataIndex: 'first',
        key: 'first',
        width: 180,
        render: (text, record, index) => {
            return (<>{new Date(record.first).toLocaleString()}</>)
        },
        sorter: (a, b) => a.first - b.first,
    },

    {
        title: 'Last seen',
        dataIndex: 'last',
        key: 'last',
        width: 180,
        render: (text, record, index) => {
            return (<>{new Date(record.last).toLocaleString()}</>)
        },
        sorter: (a, b) => a.last - b.last,
    },
    {
        title: 'Tags',
        dataIndex: 'tagShow',
        key: 'tagShow',
        align: "right"
    },
]

export function Search({ kernel }) {
    const [loaded, setLoaded] = useState(true)

    const [mode, setMode] = useState("domain")
    const [type, setType] = useState("search")
    const [search, setSearch] = useState(null)
    const [searching, setSearching] = useState(false)
    const [results, setResults] = useState([])

    const navigate = useNavigate()

    const handleSearch = async () => {
        setSearching(true)

        var tester = null;
        const data = {};
        if (type === "regex") tester = data.regex = search;
        else if (type === "search") tester = data.search = search;
        else if (mode === "network") tester = data.network = search;
        else {
            notification.warning({
                message: 'Please select a searching mode'
            })
            setResults([])
            setSearching(false)
            return;
        }
        if (!tester) {
            notification.warning({
                message: 'Please enter searching patterns'
            })
            setResults([])
            setSearching(false)
            return
        }

        if (tester.length === 0) {
            notification.warning({
                message: 'Please enter at least one searching pattern'
            })
            setResults([])
            setSearching(false)
            return
        }

        const raw = await kernel.api.post(`/cyberintel/global/search`, data);
        if (!raw) {
            notification.warning({
                message: 'Please try again later'
            })
            setResults([])
            setSearching(false)
            return;
        }
        if (raw.error) {
            notification.warning({
                message: 'Error testing the rule',
                description: raw.error
            })
            setResults([])
            setSearching(false)
            return
        }

        for (var item of raw.data.results) {
            item.domainShow = <ThreatDBIOC key={`${item.key}-domain`} kernel={kernel} value={item.domain} />

            item.tagShow = []
            for (var tag of item.tags)
                item.tagShow.push(<ThreatDBTag key={`${item.key}-${tag}`} kernel={kernel} tag={tag} />)

        }

        setTimeout(() => {
            setResults(raw.data.results)
            setSearching(false)
        }, 250)
    }


    return (
        <DiventryLayoutCyberIntel>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="CyberIntel"
                description="Hafnova CyberIntel branding search"
            />
            <DiventryLayoutContent>
                <DiventryBlock bottom={true}>
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Space.Compact>
                            <Select
                                value={mode}
                                // style={{ width: '100%' }}
                                options={[
                                    { value: "domain", label: "Domain searching" },
                                    // { value: "network", label: "Network searching" },
                                ]}
                                disabled={searching}
                                onChange={(value) => {
                                    setMode(value)
                                    if (value === "domain")
                                        setType("search")
                                    setSearch(null)
                                }}
                            />

                            {mode === "domain" ?
                                <Select
                                    value={type}
                                    // style={{ width: '100%' }}
                                    options={[
                                        { value: "regex", label: "Regex based search" },
                                        { value: "search", label: "Dichotomy based search" },
                                    ]}
                                    disabled={searching}
                                    onChange={(value) => {
                                        setType(value)
                                        setSearch(null)
                                    }}
                                />
                                : null}

                            <Select
                                value={search}
                                mode="tags"
                                disabled={searching}
                                style={{ width: 300 }}
                                onChange={(value) => {
                                    setSearch(value)
                                }}
                            />

                            <Button
                                disabled={searching}
                                type="primary"
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Space.Compact>
                    </div>
                </DiventryBlock>

                {!searching && results.length === 0 ?
                    <DiventryBlock>
                        <div style={{
                            paddingTop: 50,
                            paddingBottom: 50,
                        }}>
                            <Empty
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                styles={{
                                    image: {
                                        height: 60,
                                    },
                                }}
                                description={
                                    <>
                                        No result
                                    </>
                                }
                            >
                            </Empty>
                        </div>
                    </DiventryBlock>
                    : null}

                {searching ?
                    <DiventryBlock>
                        <Spin
                            spinning={searching}
                            tip="Searching in a ton of data"
                        >
                            <div style={{ height: 300 }}></div>
                        </Spin>
                    </DiventryBlock>
                    : null}

                {mode === "domain" && !searching && results.length > 0 ?
                    <DiventryBlock>
                        <div style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
                            CyberIntel search in ThreatDB Domains
                        </div>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "0.7em" }}>
                            Result is limited to 10'000 entries
                        </div>

                        <Table
                            size="small"
                            tableLayout="fixed"
                            dataSource={results}
                            columns={domainColumns}
                            // loading={searching}
                            pagination={{
                                showTotal: (total) => `Total ${results.length} results`,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                position: ["topCenter", "bottomCenter"],
                                defaultPageSize: 50,
                                pageSizeOptions: [50, 100, 250]
                            }}
                        />
                    </DiventryBlock>
                    : null}

            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />
        </DiventryLayoutCyberIntel>

    );
}