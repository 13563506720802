import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"

import {
    Button,
    Tag, Space,
    Descriptions,
    Form,
    Input,
    Select,
    Spin,
    Switch,
    Skeleton,
    notification
} from 'antd'

import { DiventryBlock } from '../../../../Templates/Layout'
import { getCoverageColor } from '../../../../Templates/Utils'

export function Summary({ kernel }) {
    const [loaded, setLoaded] = useState(true);

    // main document
    const [info, setInfo] = useState({});
    const [edit, setEdit] = useState(false);

    // for source addition
    const [isModalSource, setIsModalSource] = useState(false);
    const [titleModalSource, setTitleModalSource] = useState("");
    const [sourceForm] = Form.useForm();

    // for update
    const [isModalUpdate, setIsModalUpdate] = useState(false);
    const [updateForm] = Form.useForm();

    // for resolvers
    const [resolvers, setResolvers] = useState({})

    // for instance summary
    const [sumData, setSumData] = useState(null)

    // for navigation
    const navigate = useNavigate();
    const { id } = useParams();

    // async load
    useEffect(() => {
        async function fetch() {
            {
                const raw = await kernel.api.get(`/cyberintel/instances/get?id=${id}`);
                if (!raw || raw.error) {
                    notification.error({
                        message: 'Error.Instance',
                        description: raw.error
                    });
                    return;
                }
                setInfo(raw.data)
            }

            {
                const raw = await kernel.api.get('/cyberintel/resolvers/in-use')
                if (!raw || raw.error) {
                    notification.error({
                        message: 'Error.Resolvers',
                        description: raw.error
                    })
                    return
                }

                const list = {}
                for (var resolver of raw.data)
                    list[resolver.key] = resolver

                setResolvers(list)
            }

            {
                setSumData(null)

                const raw = await kernel.api.get(`/cyberintel/instances/summary/${id}`);
                if (!raw || raw.error) {
                    notification.error({
                        message: 'Error.Summary',
                        description: raw.error
                    });
                    return;
                }

                setTimeout(() => setSumData(raw.data), 250)
            }

        }

        fetch()
    }, [loaded]);


    return (
        <>
            <DiventryBlock bottom={true}>
                <Space.Compact>
                    {edit === true ?
                        <>
                            <Button type="primary" onClick={async () => {
                                const data = sourceForm.getFieldsValue();
                                data.id = info.id;
                                const ret = await kernel.api.post(`/cyberintel/instances/update`, data);
                                if (!ret) {
                                    notification.error({
                                        message: 'Service unavailable'
                                    });
                                    return;
                                }
                                if (ret.error) {
                                    notification.error({
                                        message: ret.error
                                    });

                                    if (ret.fields) {
                                        const fields = [];
                                        for (var key in ret.fields) {
                                            const message = ret.fields[key];
                                            const k = key.split(".");
                                            fields.push({ name: k[0], errors: message });
                                        }

                                        sourceForm.setFields(fields);
                                    }

                                    return;
                                }

                                notification.success({
                                    message: "Instance saved"
                                });

                                setEdit(false);
                                setLoaded(!loaded);
                            }}>Save</Button>
                            <Button onClick={() => {
                                setEdit(false);
                            }}>Cancel</Button>
                        </>
                        :
                        <Button type="primary" onClick={() => {
                            sourceForm.resetFields();
                            sourceForm.setFieldsValue(info);
                            setEdit(true);
                        }}>Edit</Button>}


                </Space.Compact>
            </DiventryBlock>

            <DiventryBlock bottom={true}>
                <Form form={sourceForm}>
                    <Descriptions
                        bordered
                        column={{
                            xxl: 2,
                            xl: 2,
                            lg: 2,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item span={2} label="Name">
                            {edit === true ?
                                <Form.Item name="name" noStyle={true}>
                                    <Input />
                                </Form.Item>
                                :
                                <strong>{info.name}</strong>}
                        </Descriptions.Item>

                        <Descriptions.Item label="Visibility">
                            {edit === true ?
                                <Form.Item name="public" noStyle={true}>
                                    <Switch
                                        checkedChildren="Public"
                                        unCheckedChildren="Private"
                                    />
                                </Form.Item>
                                :
                                <>{info.public === true ? "Public" : "Private"}</>}
                        </Descriptions.Item>

                        <Descriptions.Item label="For statistics">
                            {edit === true ?
                                <Form.Item name="inStats" noStyle={true}>
                                    <Switch
                                        checkedChildren="Included"
                                        unCheckedChildren="Excluded"
                                    />
                                </Form.Item>
                                :
                                <>{info.inStats === true ? "Included" : "Excluded"}</>}
                        </Descriptions.Item>

                        <Descriptions.Item label="Watch 0day">
                            {edit === true ?
                                <Form.Item name="day0" noStyle={true}>
                                    <Switch
                                        checkedChildren="Watched"
                                        unCheckedChildren="Avoid"
                                    />
                                </Form.Item>
                                :
                                <>{info.day0 === true ? "Watched" : "Avoided"}</>}
                        </Descriptions.Item>

                        <Descriptions.Item label="Creation date">
                            {new Date(info.createdAt).toLocaleString()}
                        </Descriptions.Item>

                        <Descriptions.Item label="Last update">
                            {new Date(info.updatedAt).toLocaleString()}
                        </Descriptions.Item>

                        {/* <Descriptions.Item span={2} label="IPs & networks" h>
                            {edit === true ?
                                <Form.Item name="ips" noStyle={true}>
                                    <Select
                                        mode="tags"
                                        style={{
                                            width: "100%"
                                        }}
                                        showSearch />
                                </Form.Item>
                                :
                                <>
                                    {info?.ips ?
                                        info.ips.map((el) => <Tag key={"_tag_" + el}>{el}</Tag>)
                                        : null}
                                </>}
                        </Descriptions.Item>

                        <Descriptions.Item span={2} label="Domains">
                            {edit === true ?
                                <Form.Item name="domains" noStyle={true}>
                                    <Select
                                        mode="tags"
                                        style={{
                                            width: "100%"
                                        }}
                                        showSearch />
                                </Form.Item>
                                :
                                <>
                                    {info?.domains ?
                                        info.domains.map((el) => <Tag key={"_tag_" + el}>{el}</Tag>)
                                        : null}
                                </>}
                        </Descriptions.Item> */}

                    </Descriptions>
                </Form>
            </DiventryBlock >

            <DiventryBlock>
                {sumData ? <>
                    <Descriptions
                        bordered
                        column={{
                            xxl: 2,
                            xl: 2,
                            lg: 2,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >

                        {sumData?.results?.total ? <>
                            <Descriptions.Item label="Total.Detection">
                                {sumData?.results?.total}
                            </Descriptions.Item>
                        </> : null}
                        {sumData?.results?.coverage ? <>
                            <Descriptions.Item label="Coverage">
                                <Tag color={getCoverageColor(sumData.results.coverage)}>{sumData.results.coverage.toFixed(2)}%</Tag>
                            </Descriptions.Item>
                        </> : null}

                        {sumData?.results?.states ? <>
                            {sumData.results.states.map((item) => {
                                return (
                                    <Descriptions.Item label={"Domain." + item.key.toUpperCase()}>
                                        {showRate(item.value, sumData.results.total)}
                                    </Descriptions.Item>
                                )
                            })}
                        </> : null}

                    </Descriptions>
                </> : <Skeleton active />}
            </DiventryBlock>

            <DiventryBlock>
                {sumData ? <>
                    <Descriptions
                        bordered
                        column={{
                            xxl: 2,
                            xl: 2,
                            lg: 2,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >
                        {sumData?.results?.tags ? <>
                            {sumData.results.tags.map((item) => {
                                return (
                                    <Descriptions.Item label={"Tag." + item.key.toUpperCase()}>
                                        {showRate(item.value, sumData.results.total)}
                                    </Descriptions.Item>
                                )
                            })}
                        </> : null}

                    </Descriptions>
                </> : <Skeleton active />}
            </DiventryBlock>

            <DiventryBlock>

                {sumData ? <>
                    <Descriptions
                        bordered
                        column={{
                            xxl: 2,
                            xl: 2,
                            lg: 2,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >
                        {sumData?.results?.competitors ? <>
                            {sumData.results.competitors.sort((a, b) => b.value - a.value).map((item) => {
                                return (
                                    <Descriptions.Item label={resolvers[item.key]?.name}>
                                        {showRate(item.value, sumData.results.total, true)}
                                    </Descriptions.Item>
                                )
                            })}
                        </> : null}

                    </Descriptions>
                </> : <Skeleton active />}
            </DiventryBlock>
        </>
    );
}

function showRate(unit, total, tagged = false) {
    if (!unit)
        return ("-")
    const percentil = unit * 100 / total
    if (tagged === true)
        return (<>
            <Tag color={getCoverageColor(percentil)}>{unit} <small>({percentil.toFixed(2)}%)</small></Tag>
        </>)
    return (<>{unit} <small>({percentil.toFixed(2)}%)</small></>)
}