
import { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom"
import { Content } from '../../../Templates'
import {
    Table,
    Button,
    Tag,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber,
    Tooltip
} from 'antd'

import { DiventryMenu } from '../../../Templates/Menu'
import {
    DiventryLayoutCyberIntel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryBlock
} from '../../../Templates/Layout'

import { getCoverageColor } from '../../../Templates/Utils'

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record, index) => {
            const ret = [<strong style={{fontSize: "1.2em"}}>{text}</strong>]

            if(record.public === true)
                ret.push(<Tag>Public</Tag>)

            if(record.inStats === true)
                ret.push(<Tooltip title="This instance is used for global statistics"><Tag>Gather</Tag></Tooltip>)

            if(record.day0 === true)
                ret.push(<Tooltip title="0days from this instance is compiled"><Tag>0day</Tag></Tooltip>)

            return (<Space>{ret}</Space>)
        }
    },
    {
        title: 'Up',
        dataIndex: 'stats.up',
        key: 'stats.up',
        render: (text, record, index) => {
            if (!record.stats)
                return ("∞")
            return (<>{record.stats.up} {record.stats.upPercentil ? <small>({record.stats.upPercentil.toFixed(2)}%)</small> : null}</>)
        }
    },

    {
        title: 'Down',
        dataIndex: 'stats.down',
        key: 'stats.down',
        render: (text, record, index) => {
            if (!record.stats)
                return ("∞")
            return (<>{record.stats.down}</>)
        }
    },

    {
        title: 'Coverage',
        dataIndex: 'stats.coverage',
        key: 'stats.coverage',
        align: "right",
        render: (text, record, index) => {
            if (!record?.stats?.coverage)
                return ("∞")
            return (<Tag color={getCoverageColor(record.stats.coverage)}>{record.stats.coverage.toFixed(2)}%</Tag>)
        }
    },
    // {
    //     title: '',
    //     dataIndex: 'buttons',
    //     key: 'buttons',
    //     align: "right"
    // },
]

export function List({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [table, setTable] = useState([])

    // for list creation
    const [isModalCreate, setIsModalCreate] = useState(false)
    const [createForm] = Form.useForm()

    const navigate = useNavigate()

    useEffect(() => {
        async function fetch() {
            const newTable = []
            const raw = await kernel.api.get('/cyberintel/instances/list')

            if (Array.isArray(raw.data)) {
                for (let data of raw.data) {
                    data.key = data.id

                    // process button
                    data.buttons = <Space.Compact size="small">
                        {/* <Popconfirm
                            placement="bottom"
                            title="Delete this instance"
                            description="The instance will be permanently deleted"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={async () => {
                                const ret = await kernel.api.post(
                                    `/cyberintel/instances/remove`,
                                    { id: data.key }
                                )
                                if (ret.error) {
                                    notification.error({
                                        message: 'Error deleting key'
                                    })
                                    return
                                }

                                notification.success({
                                    message: 'Entry was deleted'
                                })

                                setLoaded(!loaded)
                            }}
                        >
                            <Button >Delete</Button>
                        </Popconfirm> */}


                        <Button
                            onClick={async () => {
                                navigate(`/cyberintel/instances/${data.key}/configuration`)
                            }}
                        >Detail</Button>
                    </Space.Compact>
                    newTable.push(data)
                }
                setTable(newTable)
            }
        }
        fetch()
    }, [loaded])

    // Create handler
    const handleCreateOk = async () => {
        const data = {
            ...createForm.getFieldsValue()
        }
        const ret = await kernel.api.post(`/cyberintel/instances/create`, data)
        if (!ret || ret.error) {
            notification.error({
                message: 'Error updating information'
            })
            return
        }
        notification.success({
            message: 'New instance created'
        })
        setIsModalCreate(false)
        setLoaded(!loaded)
        navigate(`/cyberintel/instances/${ret.data.id}/summary`)
    }
    const handleCreateCancel = () => {
        setIsModalCreate(false)
    }

    return (
        <DiventryLayoutCyberIntel>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title="CyberIntel Instances"
                description="Active CyberIntel instances"
            />
            <DiventryLayoutContent>

                <Modal title="Creating an instance" open={isModalCreate} onOk={handleCreateOk} onCancel={handleCreateCancel}>
                    <Form
                        // layout="vertical"
                        form={createForm}
                    >
                        <Form.Item label="Instance name" name="name">
                            <Input />
                        </Form.Item>

                        <Form.Item label="Is instance public" name="public">
                            <Switch checkedChildren="Yes" unCheckedChildren="No" />
                        </Form.Item>

                    </Form>
                </Modal>

                <p>
                    <Space>
                        <Button onClick={() => {
                            createForm.resetFields()
                            setIsModalCreate(true)
                        }}>
                            Create new instance
                        </Button>
                    </Space>
                </p>
                <DiventryBlock>
                    <Table
                        dataSource={table}
                        columns={columns}
                        onRow={(record, index) => {
                            return {
                                onClick: (event) => {
                                    document.body.style.cursor = "inherit"
                                    navigate(`/cyberintel/instances/${record.id}/summary`)
                                },
                                onMouseEnter: (event) => {
                                    document.body.style.cursor = "pointer"
                                },
                                onMouseLeave: (event) => {
                                    document.body.style.cursor = "inherit"
                                },
                            };
                        }}
                        pagination={{
                            defaultPageSize: 50,
                            pageSizeOptions: [50, 100, 250]
                        }}
                    />
                </DiventryBlock>
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel} />
        </DiventryLayoutCyberIntel>

    );
}