import { useEffect, useState } from 'react'

import { Link, useParams } from "react-router-dom"
import { Content } from '../../../../Templates'
import {
    Select,
    Space,
    Slider,
    Button,
    Table,
    Tag,
    Descriptions,
    Switch,
    notification
} from 'antd'

import punycode from 'punycode/'

import * as XLSX from "xlsx"
import { saveAs } from "file-saver"

import { DiventryMenu } from '../../../../Templates/Menu'
import {
    DiventryBlock,
    DiventryLayoutCyberIntel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventryTitle
} from '../../../../Templates/Layout'

import { ThreatDBTag } from '../../../../Templates/Tag'
import { ThreatDBIOC } from '../../../../Templates/ThreatDBIOC'

const baseColumns = [
    {
        title: 'Name',
        dataIndex: 'domainShow',
        key: 'domainShow',
        // render: (text, record, index) => {
        //     if (record.parse.punycode === true) {
        //         return (<Space><strong>{punycode.toUnicode(record.domain)}</strong> <small>({record.domain})</small><Tag>Punycode</Tag></Space>)
        //     }
        //     return (<strong>{record.domain}</strong>)
        // }
    },
    {
        title: 'States',
        dataIndex: 'states',
        key: 'states',
        width: 150,
        render: (text, record, index) => {
            const ret = []
            for (var tag of record.states)
                ret.push(<Tag>{tag}</Tag>)
            return (ret)
        }
    }
]

const generalColumns = [
    ...baseColumns,
    {
        title: 'Tags',
        dataIndex: 'tagShow',
        key: 'tagShow',
        width: 150,
    },
    {
        title: 'Coverage',
        dataIndex: 'coverage',
        key: 'coverage',
        width: 100,
        render: (text, record, index) => {
            return (<>{record.coverage.toFixed(2)}%</>)
        },
        sorter: (a, b) => a.coverage - b.coverage,
    },
    {
        title: 'First',
        dataIndex: 'first',
        key: 'first',
        width: 130,
        render: (text, record, index) => {
            const d = (new Date(record.first).toLocaleString()).split(", ")
            return (<small>{d[0]}<br />{d[1]}</small>)
        },
        sorter: (a, b) => a.first - b.first,
    },
    {
        title: 'Last',
        dataIndex: 'last',
        key: 'last',
        width: 130,
        render: (text, record, index) => {
            const d = (new Date(record.last).toLocaleString()).split(", ")
            return (<small>{d[0]}<br />{d[1]}</small>)
        },
        sorter: (a, b) => a.first - b.first,
    },
    // {
    //     title: 'Update',
    //     dataIndex: 'updatedAt',
    //     key: 'updatedAt',
    //     width: 130,
    //     render: (text, record, index) => {
    //         const d = (new Date(record.updatedAt).toLocaleString()).split(", ")
    //         return (<small>{d[0]}<br/>{d[1]}</small>)
    //     },
    //     sorter: (a, b) => a.updatedAt - b.updatedAt,
    // },
    {
        title: 'Rule',
        dataIndex: 'ruleIdShow',
        key: 'ruleIdShow',
    },
]

const resolvColumns = [
    ...baseColumns,
    {
        title: 'Resolving',
        dataIndex: 'resolving',
        key: 'resolving',
        render: (text, record, index) => {
            if (!record?.resolved?.base?.value)
                return ("-")
            return (
                <div style={{ maxWidth: 200 }}>
                    {record.resolved.base.value.map((a) => {
                        return (<>{a}<br /></>)
                    })}
                </div>
            )
        }
    },
    {
        title: 'Name server',
        dataIndex: 'nameServer',
        key: 'nameServer',
        render: (text, record, index) => {
            if (!record?.resolved?.ns?.value)
                return ("-")
            return (
                <div style={{ maxWidth: 200 }}>
                    {record.resolved.ns.value.map((a) => {
                        return (<>{a}<br /></>)
                    })}
                </div>
            )
        }
    },
]


const competitorsColumns = [
    ...baseColumns,
    {
        title: 'Competitors',
        dataIndex: 'competitors',
        key: 'competitors',
        align: "right",
        render: (text, record, index) => {
            if (!record?.doubleCheck || record.doubleCheck.length === 0)
                return ("-")
            return (
                <div >
                    {record.doubleCheck.map((a) => {
                        if (a.active === true)
                            return (<Tag color="volcano">{a.key}</Tag>)
                        return (<Tag color="green">{a.key}</Tag>)
                    })}
                </div>
            )
        }
    },
]

const views = [
    { value: "general", label: "General.Info", obj: generalColumns },
    { value: "resolv", label: "DNS.Resolution", obj: resolvColumns },
    { value: "competitors", label: "Competitors.Verify", obj: competitorsColumns },
    // { value: "rules", label: "Rule.Details", obj: generalColumns }
]

const formatter = (value) => `Coverage less than ${value}%`;

export function Detections({ kernel }) {
    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)

    const [tableCols, setTableCols] = useState(generalColumns)
    const [table, setTable] = useState([])

    // view
    const [view, setView] = useState("general")

    // for navigation
    const [states, setStates] = useState(["all"])
    const [coverage, setCoverage] = useState(100)
    const [punycode, setPunycode] = useState(false)

    // for resolvers
    const [resolvers, setResolvers] = useState(null)

    // for rules
    const [rules, setRules] = useState(null)

    const { id } = useParams()

    // async load
    useEffect(() => {
        async function fetch() {
            setLoading(true)

            var irules = rules
            {
                if (!irules) {
                    const ret = await kernel.api.get(`/cyberintel/rules/list?instanceId=${id}`);
                    if (!ret || ret.error) {
                        notification.error({
                            message: 'Error.Rule',
                            description: ret.error
                        });
                        return;
                    }

                    const nrules = {}
                    for (var item of ret.data) {
                        nrules[item.id] = item
                    }
                    irules = nrules
                    setRules(nrules)
                }
            }


            {
                if (!resolvers) {
                    const raw = await kernel.api.get('/cyberintel/resolvers/in-use')
                    if (!raw || raw.error) {
                        notification.error({
                            message: 'Error.Resolvers',
                            description: raw.error
                        })
                        return
                    }

                    const list = {}
                    for (var resolver of raw.data)
                        list[resolver.key] = resolver

                    setResolvers(list)
                }
            }

            {
                const keys = {}
                for (var v of states)
                    keys[v] = true

                delete keys.all

                const search = []

                const searchStates = Object.keys(keys)
                if (searchStates.length > 0)
                    search.push("states=" + searchStates.join(","))

                if (coverage >= 0)
                    search.push("coverage=" + coverage)

                if (punycode === true)
                    search.push("punycode=true")

                const ret = await kernel.api.get(`/cyberintel/instances/results/${id}?${search.join("&")}`);
                if (!ret || ret.error) {
                    notification.error({
                        message: 'Error.Getting.Results',
                        description: ret.error
                    });
                    return;
                }

                for (var item of ret.data.results) {

                    // item.domainShow = <ThreatDBIOC key={`${item._id}-${tag}`} kernel={kernel} value={item.domain} />
                    item.domainShow = <strong>{item.domain}</strong>

                    item.tagShow = []
                    for (var tag of item.tags) {
                        item.tagShow.push(<ThreatDBTag key={`${item._id}-${tag}`} kernel={kernel} tag={tag} />)
                    }

                    item.ruleIdShow = irules[item.ruleId] ? irules[item.ruleId].name : item.ruleId
                }

                setTimeout(() => {
                    setTable(ret.data.results)
                    setLoading(false)
                }, 250)
            }

        }
        fetch();
    }, [loaded]);

    const handleStates = (values) => {
        const keys = {}
        for (var v of values)
            keys[v] = true
        if (keys.all === true && values.length === 1)
            setStates(["all"])
        else {
            delete keys.all
            setStates(Object.keys(keys))
        }
        setLoaded(!loaded)
    }

    const handleCoverage = (newValue) => {
        setLoaded(!loaded)
    }

    return (<>

        <DiventryBlock bottom={true}>
            <Space>
                <div style={{
                    width: 200
                }}>
                    <Select
                        style={{ width: '100%' }}
                        // placeholder="States filtering"
                        onChange={(value) => {
                            for (var v of views) {
                                if (v.value === value)
                                    setTableCols(v.obj)
                            }
                            setView(value)
                        }}
                        value={view}
                        options={views}
                    />
                </div>

                <div style={{
                    width: 100
                }}>
                    <Slider
                        defaultValue={coverage}
                        value={coverage}
                        tooltip={{
                            formatter,
                        }}
                        onChangeComplete={handleCoverage}
                        onChange={(newValue) => {
                            setCoverage(newValue)
                        }}
                    />
                </div>
                <div style={{
                    width: 250
                }}>
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        placeholder="States filtering"
                        onChange={handleStates}
                        defaultValue={["all"]}
                        value={states}
                        allowClear
                        options={[
                            { value: "all", label: "All" },
                            { value: "up", label: "Domain UP" },
                            { value: "down", label: "Domain DOWN" },
                            { value: "nsup", label: "Name server UP" },
                            { value: "nsdown", label: "Name server DOWN" },
                        ]}
                    />
                </div>

                <div>
                    <Switch
                        checkedChildren="Punycode"
                        unCheckedChildren="All"
                        defaultChecked
                        value={punycode}
                        onChange={() => {
                            setPunycode(!punycode)
                            setLoaded(!loaded)
                        }} />
                </div>

                <Space.Compact>
                    <Button onClick={() => {
                        setStates(["all"])
                        setCoverage(100)
                        setPunycode(false)
                        setLoaded(!loaded)
                    }}>
                        Reset
                    </Button>

                    <Button type="primary" disabled={!resolvers || !rules ? true : false} onClick={() => {
                        const titles = ["Name", "Rule", "First detection", "Create", "Update", "States", "Tags", "Coverage", " "]

                        for (var res of Object.values(resolvers))
                            titles.push(res.key)

                        const data = [
                            titles,
                        ]

                        for (var item of table) {
                            const insert = [
                                item.domain,
                                rules[item.ruleId] ? rules[item.ruleId].name : item.ruleId,
                                new Date(item.first).toLocaleString(),
                                new Date(item.createdAt).toLocaleString(),
                                new Date(item.updatedAt).toLocaleString(),
                                item.states.join(", "),
                                item.tags.join(", "),
                                item.coverage ? item.coverage : "100",
                                " "
                            ]

                            const kdc = {}
                            for (var dc of item.doubleCheck)
                                kdc[dc.key] = dc

                            for (var res of Object.values(resolvers)) {
                                if (!kdc[res.key]) {
                                    insert.push("Unknown")
                                    continue
                                }

                                insert.push(kdc[res.key].active === true ? "Passed" : "Blocked")
                            }

                            data.push(insert)
                        }

                        const ws = XLSX.utils.aoa_to_sheet(data);
                        const wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, "Feuille1");

                        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

                        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                        saveAs(blob, `hafnova-cyberintel-${id}.xlsx`)
                    }}>
                        Download
                    </Button>

                </Space.Compact>

                <div style={{
                    width: 500
                }}>
                    {table.length} Found
                </div>
            </Space>
        </DiventryBlock>
        <DiventryBlock>
            <Table
                size="small"
                tableLayout="fixed"
                dataSource={table}
                columns={tableCols}
                loading={loading}
                pagination={{
                    showTotal: (total) => `Total ${table.length} results`,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    position: ["topCenter", "bottomCenter"],
                    defaultPageSize: 50,
                    pageSizeOptions: [50, 100, 250]
                }}
            />
        </DiventryBlock>
    </>)
}