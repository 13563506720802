import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
    Tag,
    Space,
    Button,
    message,
    Popconfirm,
    Input, notification,
    Descriptions,
    Row,
    Col,
    Checkbox,
    Dropdown,
    Form,
    Modal,
    InputNumber,
    Spin,
    Switch,
    Select,
    Menu
} from 'antd';
import {
    SearchOutlined,
    LeftOutlined,
    SyncOutlined,
    LockOutlined,
    UnlockOutlined,
    DoubleRightOutlined,
    GlobalOutlined,
    RightOutlined,
    DownOutlined
} from '@ant-design/icons';
import { LogTable } from '../../../Templates/Logs';
import { ShowTag } from "../../ThreatDB";
import Link from "antd/es/typography/Link";
import { useTranslation } from 'react-i18next';
import Brands from '../../DoHzel/Brands/Index'

import {
    ThreatDBTag
} from '../../../Templates/Tag'

import {
    ThreatDBIOC
} from '../../../Templates/ThreatDBIOC'

export const detectionMethods = {
    "dns-request": "DNS request",
    "dns-ip-response": "IP DNS response",
    "ip-output": "IP output",
}

export const LogsColors = {
    blocking: "4px solid #a14838",
    detecting: "4px solid #e58900",
    passing: "4px solid #22d933",
    bypassing: "4px solid #4974a5",
}

export const LogsPolicy = {
    blocking: "Blocked",
    detecting: "Detect only",
    passing: "Accepted",
    bypassing: "Bypassed",
}

export const LogsDescription = [
    {
        label: 'Passed',
        value: 'passing',
    },
    {
        label: 'Detected',
        value: 'detecting',
    },
    {
        label: 'Blocked',
        value: 'blocking'
    },
];


export function LogsStream({ kernel, url, endpoint, logType, profileId, deviceId }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [search, setSearch] = useState(searchParams.get('search') || "");
    const [filters, setFilters] = useState({
        search: searchParams.get('search') || "",
        policies: ['detecting', 'blocking']
    });
    const [logs, setLogs] = useState([]);
    const [devices, setDevices] = useState([]);
    const [document, setDocument] = useState({});
    const [initial, setInitial] = useState(true);
    const [domains, setDomains] = useState([]);
    const [blockDomains, setBlockDomains] = useState([]);
    const [expands, setExpands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [device, setDevice] = useState({});
    const [profileRoles, setProfileRoles] = useState([]);
    const [selectedSize, setSelectedSize] = useState(localStorage?.getItem('sizePager') ?? '100');
    const [isModalUpdate, setIsModalUpdate] = useState(false);
    const [insertionMode, setInsertionMode] = useState(true)
    const [user, setUser] = useState({})
    const [form] = Form.useForm()

    const { t } = useTranslation();

    const handleSizeClick = (e) => {
        const selectedLabel = e.key; // Ou e.item.props.children pour obtenir le texte
        setSelectedSize(selectedLabel);
        const params = doFilter({ gte: undefined, limit: selectedLabel });
        localStorage?.setItem('sizePager', selectedLabel)
        navigate(`${url}?${params.join("&")}`);
    };

    const confirm = (e) => {
        console.log(e);
        message.success(t('Logs.successDomainAdd'));
    };

    const cancel = (e) => {
        console.log(e);
        message.error(t('Logs.noAction'));
    };

    const sizeChangerProps = (
        <Menu onClick={handleSizeClick}>
            <Menu.Item key="50">{t('Logs.pagination50PerPage')}</Menu.Item>
            <Menu.Item key="100">{t('Logs.pagination100PerPage')}</Menu.Item>
            <Menu.Item key="200">{t('Logs.pagination200PerPage')}</Menu.Item>
            <Menu.Item key="300">{t('Logs.pagination300PerPage')}</Menu.Item>
            <Menu.Item key="400">{t('Logs.pagination400PerPage')}</Menu.Item>
            <Menu.Item key="500">{t('Logs.pagination500PerPage')}</Menu.Item>
        </Menu>
    );


    function buildFilter(overWrite) {
        const params = [];

        const after = { ...filters, ...overWrite };
        for (var key in after) {
            const value = after[key];
            if (Array.isArray(value) && value.length > 0)
                params.push(`${key}=${value.join(",")}`);
            else if (typeof value === "string" && value.length > 0)
                params.push(`${key}=${value}`);
        }

        return (params);
    }

    useEffect(() => {
        async function fetch() {
            var response = await kernel.api.get(`/dohzel/device/get?id=${deviceId}`)
            if (response.error) {
                notification.error({
                    message: t('Logs.errorFetchingInformation'),
                    description: response.error
                })

                return
            }
            setDevice(response.data)
        }
        if (logType === 'device') {
            fetch();
        }
    }, [])

    useEffect(() => {
        async function fetch() {
            var response = await kernel.api.get("/dohzel/profile/me")
            if (response.error) {
                return
            }

            setProfileRoles(response.data.list?.find(profile => profile.id == profileId)?.role);
        }
        fetch();
    }, [])

    useEffect(() => {
        async function fetch() {
            setLoading(true)
            var response = await kernel.api.get(`/dohzel/device/list?profileId=${profileId}`)
            if (response.error) {
                setLoading(false);
                return
            }
            setDevices(
                response.data?.sort((device1, device2) => {
                    if (!device1?.name) return -1;
                    if (!device2?.name) return 1;
                    return device1.name.toLowerCase()?.trim()?.localeCompare(device2?.name?.trim().toLowerCase());
                })
                    ?.map(device => ({
                        ...device,
                        value: device.id
                    }))
            );
            setLoading(false);
        }
        fetch()
    }, [profileId])

    useEffect(() => {
        var filteredEnpoint = { ...filters };

        const gte = searchParams.get('gte');
        if (gte && gte.length > 0) {
            filteredEnpoint.gte = gte;
            setAutoRefresh(false);
        }
        else if (filters.gte)
            delete filteredEnpoint.gte;

        const search = searchParams.get('search');
        if (search && search.length > 0) {
            filteredEnpoint.search = search;
        }
        else if (filters.search)
            delete filteredEnpoint.search;

        const policies = searchParams.get('policies');
        if (policies && policies.length > 0) {
            filteredEnpoint.policies = policies.split(",");
        }
        else if (filters.policies && initial === false)
            delete filteredEnpoint.policies;

        setFilters(filteredEnpoint);
        setInitial(false);
    }, [searchParams, url, endpoint]);

    useEffect(() => {
        setSearch("");
        setFilters({
            search: "",
            policies: ['detecting', 'blocking']
        });
    }, [url, endpoint]);

    useEffect(() => {
        var stop = false;
        var timer = null;
        const fetch = async () => {
            timer = null;
            if (stop === true) return;

            const raw = await kernel.api.get(`${endpoint}?${buildFilter(filters).join("&")}&limit=${selectedSize}`);
            if (raw.error) {
                notification.error({
                    message: t('Logs.errorFetchingLogs'),
                    description: raw ? raw.error : null
                });
                return;
            }

            // force to separe data to document
            setLogs([...raw.data.data, ...logs?.filter(log => expands?.includes(log.key) && !raw.data.data?.map(item => item.key)?.includes(log.key))]);
            delete raw.data.data;
            setDocument(raw.data);

            // auto refresh
            if (autoRefresh === true)
                timer = setTimeout(fetch, 5000);
        }
        fetch();

        return (() => {
            stop = true;
            if (timer)
                clearTimeout(timer);
        })
    }, [filters, autoRefresh, expands]);

    useEffect(() => {
        async function fetch() {
            const ret = await kernel.api.get(`/dohzel/ablist/list?${logType == 'profile' ? `profileId=${profileId}` : (device?.deviceManagement == 'mdm' ? `profileId=${device?.profileId}` : `deviceId=${deviceId}`)}&list=allow`);
            if (!ret || ret.error) {
                return;
            }
            setDomains(ret.data);
        }
        fetch();
    }, [loading, device]);

    useEffect(() => {
        async function fetch() {
            const ret = await kernel.api.get(`/dohzel/ablist/list?${logType == 'profile' ? `profileId=${profileId}` : (device?.deviceManagement == 'mdm' ? `profileId=${device?.profileId}` : `deviceId=${deviceId}`)}&list=block`);
            if (!ret || ret.error) {
                return;
            }
            setBlockDomains(ret.data)
        }
        fetch();
    }, [loading, device]);

    useEffect(() => {
        async function fetch() {
            var cUser = await kernel.api.get("/user/me")
            if (cUser.error)
                return
            setUser(cUser.data)
        }
        fetch()
    }, [])

    function doFilter(overWrite) {
        const params = [];
        const after = { ...filters, ...overWrite };
        for (var key in after) {
            const value = after[key];
            if (Array.isArray(value) && value.length > 0)
                params.push(`${key}=${value.join(",")}`);
            else if (typeof value === "string" && value.length > 0)
                params.push(`${key}=${value}`);
        }
        return (params);
    }

    const addToList = async (input, type = 'allow') => {
        setLoading(true);
        const data = {
            list: type,
            [logType == 'profile' ? 'profileId' : (device?.deviceManagement == 'mdm' ? 'profileId' : 'deviceId')]: (logType == 'profile' ? profileId : (device?.deviceManagement == 'mdm' ? device?.profileId : deviceId))
        };

        var masked = input.split("/");
        var detection = "Domain";
        if (/^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/.test(masked[0])) {
            detection = "IPv4";
            data.type = "cidr";
            data.cidr = input;
        }
        else if (masked[0].indexOf(":") > 0) {
            detection = "IPv6";
            data.type = "cidr";
            data.cidr = input;
        }
        else {
            data.type = "domain";
            const t = input.split(".");
            if (t[0] === "*") {
                data.domainWildcard = true;
                t.shift();
                data.domain = t.join(".");
            }

            else
                data.domain = input;
        }

        const ret = await kernel.api.post('/dohzel/ablist/create', data)
        if (!ret || ret.error) {
            notification.error({
                message: t('Logs.errorAddRule'),
                description: ret.error
            });
            return;
        }

        setLoading(false);
    }

    const disabledInList = async (id) => {
        console.log(id)
        const data = {
            id,
            activate: false
        };
        setLoading(true);
        const ret = await kernel.api.post(`/dohzel/ablist/update`, data);
        setLoading(false);
        if (!ret || ret.error) {
            notification.error({
                message: t('Logs.errorChangingMode'),
                description: ret.error
            });
            return;
        }
    }

    const removeFromList = async (entry, domains) => {
        setLoading(true);
        const domain = domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain);
        const data = { id: domain.id };
        const ret = await kernel.api.post(`/dohzel/ablist/remove`, data);
        if (!ret || ret.error) {
            notification.error({
                message: t('Logs.errorDeletingEntry'),
                description: ret.error
            });
            return;
        }
        setLoading(false);

    }

    const handleExpan = (entry) => {
        const item = expands?.find((expansion) => expansion === entry.id)
        if (item) {
            setExpands(expands?.filter((expansion) => expansion !== entry.id));
        }
        else {
            setExpands([...expands, entry?.id]);
        }
    }

    const handleDomainMeasurement = async (id, entry) => {
        setLoading(true);
        const infoDomain = await kernel.api.get(`/threatdb/info/domain?query=${id}`)
        if (infoDomain.error || !infoDomain?.data?.key) {
            setLoading(false);
            setInsertionMode(true)
            form.resetFields()
            form.setFieldsValue({
                domain: id
            })
            setIsModalUpdate(true);
            return
        }

        const raw = await kernel.api.get(`/threatdb/list/view/domains/list/${kernel.config.counterMeasureList}?equal=${infoDomain.data.key}`)
        if (raw.error || !raw?.data?.data?.length) {
            // manage insertion
            setLoading(false)
            setInsertionMode(true)
            form.resetFields()
            form.setFieldsValue({
                domain: id
            })
            setIsModalUpdate(true);
            return
        }

        const data = raw.data.data[0]
        data.tags = Object.keys(data.tags)
        data.tagsShow = data.tags.map((tag) => {
            return (<ThreatDBTag key={`${data.key}-${tag}`} kernel={kernel} tag={tag} />)
        })

        form.resetFields()
        form.setFieldsValue(data)
        setInsertionMode(false)
        setLoading(false);
        setIsModalUpdate(true);
    }

    // Update handler
    const handleUpdateOk = async () => {
        setLoading(true)

        if (insertionMode === true) {
            const data = [{ ...form.getFieldsValue(), type: 1 }]
            const ret = await kernel.api.post(`/threatdb/list/insert/${kernel.config.counterMeasureList}`, data)
            if (!ret || ret.error) {
                notification.error({
                    message: t('Logs.errorAddingSource'),
                    description: ret.error
                })
            }
            else {
                notification.success({
                    message: t('Logs.indicatorsPushedQueue')
                })
            }
        }
        else {
            const data = {
                ...form.getFieldsValue()
            }

            const ret = await kernel.api.post(`/threatdb/list/manipulation/domain/update/${kernel.config.counterMeasureList}`, data)
            if (ret.error) {
                notification.error({
                    message: t('Logs.errorUpdatingInformation'),
                    description: Object.values(ret.fields).map((el) => <div key={el}>{el}</div>)
                })
                setLoading(false)
                return
            }
            if (ret.data === true) {
                notification.success({
                    message: t('Logs.entryUpdated')
                })
            }
            else {
                notification.warning({
                    message: t('Logs.updateRequestQueued')
                })
            }
        }

        setLoading(false)
        setIsModalUpdate(false)
    }

    const handleUpdateCancel = () => {
        setIsModalUpdate(false)
    }

    const handleDeviceSelected = (value) => {
        console.log(`selected ${value}`);
        const params = doFilter({ ...filters, limit: selectedSize, device: value });
        navigate(`${url}?${params.join("&")}`);
        return;
    };

    return (<>
        {insertionMode !== true ?
            <Modal title={t('Logs.updateEntryInformation')} open={isModalUpdate} onOk={handleUpdateOk} onCancel={handleUpdateCancel}>
                <Spin spinning={loading}>
                    <Form
                        form={form}
                    >
                        <Form.Item label={t("Logs.internalKeyName")} name="key">
                            <Input disabled={true} />
                        </Form.Item>

                        <Form.Item label={t("Logs.domainName")} name="domain">
                            <Input disabled={true} />
                        </Form.Item>

                        <Form.Item label={t("Logs.currentRetention")} name="retention">
                            <InputNumber disabled={true} />
                        </Form.Item>

                        <Form.Item
                            label={t("Logs.dataRetention")}
                            name="userRetention"
                            help={t("Logs.retentionHelp")}
                        >
                            <InputNumber addonAfter={t("Logs.seconds")} />
                        </Form.Item>

                        <Form.Item label={t("Logs.listTags")} name="tags">
                            <Select mode="tags" style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item label={t("Logs.tagsForcing")} name="userTags">
                            <Select mode="tags" style={{ width: "100%" }} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            :
            <Modal
                title={t("Logs.updateEntryInformation")}
                open={isModalUpdate}
                onOk={handleUpdateOk}
                onCancel={handleUpdateCancel}
            >
                <Spin spinning={loading}>
                    <Form form={form}>
                        <Form.Item label={t("Logs.domainName")} name="domain">
                            <Input />
                        </Form.Item>
                        <Form.Item label={t("Logs.isWildcard")} name="wildcard">
                            <Switch />
                        </Form.Item>
                        <Form.Item
                            label={t("Logs.tags")}
                            name="tags"
                            help={t("Logs.tagsHelp")}
                        >
                            <Select
                                mode="tags"
                                style={{ width: "100%" }}
                                placeholder={t("Logs.tagsPlaceholder")}
                            />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        }
        <Space style={{ paddingBottom: 10 }}>
            <Input
                // allowClear
                prefix={<SearchOutlined />}
                style={{ width: 300 }}
                value={search}
                placeholder={t('Logs.typeToFilter')}
                onChange={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value === '') {
                        return;
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        const params = doFilter({ ...filters, search, limit: selectedSize });
                        navigate(`${url}?${params.join("&")}`);
                        return;
                    }
                }}
            />

            <Space.Compact>
                {document.prevCursor ?
                    <Button onClick={() => {
                        const params = doFilter({ gte: undefined, limit: selectedSize });
                        navigate(`${url}?${params.join("&")}`);
                    }}>
                        {t('Logs.showLast')}
                    </Button>
                    : null}

                {document.prevCursor ?
                    <Button type="default" onClick={() => {
                        const params = doFilter({ gte: document.prevCursor, limit: selectedSize });
                        navigate(`${url}?${params.join("&")}`);
                    }}>
                        <LeftOutlined /> {t('Logs.prev')}
                    </Button>
                    : null}

                {document.nextCursor ?
                    <Button type="default" onClick={() => {
                        const params = doFilter({ gte: document.nextCursor, limit: selectedSize });
                        navigate(`${url}?${params.join("&")}`);
                    }}>
                        {t('Logs.next')} <RightOutlined />
                    </Button>
                    : null}

                {!document.prevCursor ?
                    <Button type="default" onClick={() => {
                        setAutoRefresh(!autoRefresh);
                    }}>
                        <SyncOutlined spin={autoRefresh} />
                    </Button>
                    : null}

            </Space.Compact>

            <Dropdown overlay={sizeChangerProps}>
                <Button>
                    <Space>
                        {selectedSize} {t('Logs.perPage')}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>

            {/* <Select
                allowClear={true}
                style={{ width: 300, maxHeight: 30 }}
                placeholder="select devices"
                defaultValue={[]}
                onChange={handleDeviceSelected}
                options={devices}
                optionRender={(option) => (
                <Space>
                    <span role="img" aria-label={option?.data?.name || option?.data?.id}>
                        <Brands.BrandedDevice device={option?.data} />
                    </span>
                </Space>
                )}
            /> */}

            <Checkbox.Group
                options={LogsDescription?.map(log => ({
                    ...log,
                    label: t(`Logs.${log?.label}`),
                }))}
                value={filters.policies}
                onChange={(selected) => {
                    const params = doFilter({ policies: selected, limit: selectedSize });
                    navigate(`${url}?${params.join("&")}`);
                }}
            />
        </Space>

        <LogTable
            kernel={kernel}
            data={logs.map((entry) => {
                return ({
                    id: entry.key,
                    open: !!expands?.find((expansion) => expansion === entry.key),
                    handleExpan: handleExpan,
                    color: LogsColors[entry.policy],
                    left: <div style={{ fontWeight: 600, fontSize: 17 }}>
                        <ThreatDBIOC key={`${entry.key}-ioc`} kernel={kernel} value={entry.domain ? entry.domain : entry.ip} />
                    </div>,
                    right: <div>
                        <span style={{fontSize: "0.8em"}}>{new Date(entry.date).toLocaleString()} on </span>
                        <span style={{ fontWeight: 700, fontStyle: "italic" }}>{entry.device} </span>

                        {entry.protocol !== "doh" && entry.protocol !== "dot" ?
                            <>
                                {entry.forwardingMode === "DNS2DNS" ?
                                    <Tag
                                        color="gold"
                                        bordered={false}
                                        style={{ fontSize: 10, fontWeight: 700, cursor: "pointer" }}
                                    >
                                        <UnlockOutlined /> {entry.protocol.toUpperCase()}<DoubleRightOutlined /> {entry.forwardingMode.toUpperCase()}
                                    </Tag>
                                    :
                                    <Tag
                                        color="lime"
                                        bordered={false}
                                        style={{ fontSize: 10, fontWeight: 700, cursor: "pointer" }}
                                    >
                                        <LockOutlined /> {entry.protocol.toUpperCase()}<DoubleRightOutlined /> {entry.forwardingMode ? entry.forwardingMode.toUpperCase() : "DOH"}
                                    </Tag>
                                }
                            </> : null}

                        {entry.protocol === "doh" || entry.protocol === "dot" ?
                            <Tag
                                color="green"
                                bordered={false}
                                style={{ fontSize: 10, fontWeight: 700, cursor: "pointer" }}
                            >
                                <LockOutlined /> {entry.protocol.toUpperCase()}
                            </Tag>
                            : null}
                    </div>,
                    expand:
                        <Descriptions column={3} size="small">
                            <Descriptions.Item label={t('Logs.Date')}>
                                <strong>{new Date(entry.date).toLocaleString()}</strong>
                            </Descriptions.Item>

                            <Descriptions.Item label={t('Logs.logID')}>
                                {entry.key}
                            </Descriptions.Item>

                            <Descriptions.Item label={t('Logs.targetIP')}>
                                {entry.contactIP.startsWith("::ffff:") ? entry.contactIP?.replace(/^::ffff:/, '') : entry.contactIP}
                            </Descriptions.Item>

                            {entry.protocol ?
                                <Descriptions.Item label={t('Logs.protocol')}>
                                    {entry?.protocol?.toUpperCase() ?? "unknown"}
                                </Descriptions.Item>
                                : null}

                            <Descriptions.Item label={t('Logs.detectionMethod')}>
                                {detectionMethods[entry.mode]}
                            </Descriptions.Item>

                            {entry.recordType ?
                                <Descriptions.Item label={t('Logs.dnsRecordType')}>
                                    {entry.recordType}
                                </Descriptions.Item>
                                : null}

                            {entry.forwardingMode ?
                                <Descriptions.Item label={t('Logs.forwardedProtocol')}>
                                    {entry.forwardingMode.toUpperCase()}
                                </Descriptions.Item>
                                : null}

                            {entry.sourceIP ?
                                <Descriptions.Item label={t('Logs.internalSourceIP')}>
                                    <strong>{entry.sourceIP}</strong>
                                </Descriptions.Item>
                                : null}

                            {entry.forwardedIP ?
                                <Descriptions.Item label={t('Logs.clientIP')}>
                                    <strong>{entry.forwardedIP}</strong>
                                </Descriptions.Item>
                                : null}

                            {entry.forwardingRule && entry.forwarding ?
                                <Descriptions.Item label={t('Logs.conditionalForwarding')}>
                                    <div>To <strong>{entry.forwarding}</strong> by rule #{entry.forwardingRule}</div>
                                </Descriptions.Item>
                                : null}

                            <Descriptions.Item label={t('Logs.action')}>
                                {LogsPolicy[entry.policy]}
                            </Descriptions.Item>

                            {entry?.alert?.reasons && entry.alert.reasons.length > 0 ?
                                <Descriptions.Item label={t('Logs.reasons')} span={3}>
                                    {entry.alert.reasons.map((tag) => {
                                        return (
                                            <ShowTag
                                                key={`log-tr-${entry.id}-${tag}`}
                                                kernel={kernel}
                                                tag={tag} />
                                        );
                                    })}
                                </Descriptions.Item>
                                : null}

                            <Descriptions.Item label={t('Logs.Actions')}>
                                <div >
                                    {
                                        (entry?.policy == 'blocking' || entry.policy === 'detecting') && !domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) && !blockDomains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                        <Button size={'small'} loading={loading} type="primary" style={{ marginRight: 10 }} onClick={() => addToList(entry.domain, 'allow')}>{device?.deviceManagement == 'mdm' ? t('Logs.addToProfileAllowList') : t('Logs.addToAllowList')}</Button>
                                    }
                                    {
                                        (entry?.policy == 'blocking' || entry.policy === 'detecting') && !domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) && blockDomains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                        <Popconfirm
                                            title={t('Logs.thisItemExistsInBlockedDomains')}
                                            description={t('Logs.areYouSureToAddItToAllowedDomains')}
                                            onConfirm={async () => {
                                                await disabledInList(blockDomains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain)?.id);
                                                addToList(entry.domain, 'allow');
                                            }}
                                            onCancel={cancel}
                                            okText={t('Logs.yes')}
                                            cancelText={t('Logs.no')}
                                        >
                                            <Button size={'small'} loading={loading} type="primary" style={{ marginRight: 10 }}>{device?.deviceManagement == 'mdm' ? t('Logs.addToProfileAllowList') : t('Logs.addToAllowList')}</Button>
                                        </Popconfirm>
                                    }
                                    {
                                        (entry?.policy == 'passing' || entry.policy === 'detecting') && !blockDomains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) && !domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                        <Button size={'small'} loading={loading} type="primary" style={{ background: '#a14838' }} onClick={() => addToList(entry.domain, 'block')} >{device?.deviceManagement == 'mdm' ? t('Logs.addToProfileBlockList') : t('Logs.addToBlockList')}</Button>
                                    }
                                    {
                                        (entry?.policy == 'passing' || entry.policy === 'detecting') && !blockDomains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) && domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                        <Popconfirm
                                            title={t('Logs.thisItemExistsInAllowedDomains')}
                                            description={t('Logs.areYouSureToAddItToBlockedDomains')}
                                            onConfirm={async () => {
                                                await disabledInList(domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain)?.id);
                                                addToList(entry.domain, 'block');
                                            }}
                                            onCancel={cancel}
                                            okText={t('Logs.yes')}
                                            cancelText={t('Logs.no')}
                                        >
                                            <Button size={'small'} loading={loading} type="primary" style={{ background: '#a14838' }} >{device?.deviceManagement == 'mdm' ? t('Logs.addToProfileBlockList') : t('Logs.addToBlockList')}</Button>
                                        </Popconfirm>
                                    }
                                    {
                                        (entry?.policy == 'blocking' || entry.policy === 'detecting') && domains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                        <Button size={'small'} loading={loading} type="primary" style={{ marginRight: 10 }} onClick={() => removeFromList(entry.domain, domains)} >{device?.deviceManagement == 'mdm' ? t('Logs.removeFromProfileAllowList') : t('Logs.removeFromAllowList')}</Button>
                                    }
                                    {
                                        (entry?.policy == 'passing' || entry.policy === 'detecting') && blockDomains?.find((domain) => domain.cidr == entry.domain || domain.domain == entry.domain) &&
                                        <Button size={'small'} loading={loading} type="primary" style={{ background: '#a14838' }} onClick={() => removeFromList(entry.domain, blockDomains)}>{device?.deviceManagement == 'mdm' ? t('Logs.removeFromProfileBlockList') : t('Logs.removeFromBlockList')}</Button>
                                    }
                                    {
                                        user?.admin && (entry?.policy == 'blocking' || entry.policy === 'detecting') &&
                                        <Button size={'small'} loading={loading} type="primary" style={{ background: '#a14838' }} onClick={() => {
                                            handleDomainMeasurement(entry.domain ? entry.domain : entry.ip, entry);
                                        }}>{t('Logs.addCounterMeasure')}</Button>
                                    }
                                </div>
                            </Descriptions.Item>

                            
                        </Descriptions>

                });
            })}
        />
    </>);
}
