
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom"
import { Content } from '../../../../Templates'
import {
    Table,
    Button,
    Tag,
    Tabs,
    Space,
    Descriptions,
    Modal,
    Form,
    Input,
    Select,
    Popconfirm,
    notification,
    Switch,
    InputNumber
} from 'antd'
import { CaretLeftOutlined } from '@ant-design/icons'



import { DiventryMenu } from '../../../../Templates/Menu'
import {
    DiventryLayoutCyberIntel,
    DiventryLayoutHeader,
    DiventryLayoutContent,
    DiventryLayoutFooter,
    DiventrySide,
    DiventryBlock,
    DiventryTitle
} from '../../../../Templates/Layout'

import { Summary } from './Summary'
import { Detections } from './Detections'
import { RulesPositive } from './RulesPositive'

import { Logs } from './Logs'
import { DangerZone } from './DangerZone'

const pages = {
    'summary': {
        key: 'summary',
        menu: "Summary",
        title: "CyberIntel Summary",
        description: "Global view on CyberIntel instance",
        ref: Summary
    },
    'detections': {
        key: 'detections',
        menu: "Detections",
        title: "CyberIntel Detection",
        description: "Current detection",
        ref: Detections
    },
    'positive-rules': {
        key: 'positive-rules',
        menu: "Rules",
        title: "CyberIntel Rules",
        description: "Positive rules for detection",
        ref: RulesPositive
    },
    'logs': {
        key: 'logs',
        menu: "Logs",
        title: "CyberIntel Logs",
        description: "Logs from CyberIntel instance",
        ref: Logs
    },
    // 'notification': {
    //     key: 'notification',
    //     menu: "Notification",
    //     title: "CyberIntel Notification",
    //     description: "Manage how CyberIntel instance should manage notification",
    //     ref: Logs
    //     // children: {
    //     //     'vfsdvfds': {
    //     //         key: 'vfsdvfds',
    //     //         menu: "Configuration",
    //     //         title: "CyberIntel Configuration",
    //     //         description: "Configuring CyberIntel instance",
    //     //         ref: Configuration
    //     //     },
    //     // }
    // },
    'danger-zone': {
        key: 'danger-zone',
        menu: "Danger Zone",
        title: "CyberIntel Danger Zone",
        description: "Danger zone",
        ref: DangerZone
    },
}

export function Detail({ kernel }) {
    const params = useParams()

    const [loaded, setLoaded] = useState(true)

    // main document
    const [info, setInfo] = useState({})
    const [edit, setEdit] = useState(false)

    // for navigation
    const navigate = useNavigate()
    const { id } = useParams()

    // zone
    const [key, setKey] = useState(null);
    const [page, setPage] = useState(null);

    // async load
    useEffect(() => {
        const page = pages[params.visionPage]
        setKey(params.visionPage)
        setPage(page)

        async function fetch() {
            const raw = await kernel.api.get(`/cyberintel/instances/get?id=${id}`)
            if (raw.error || !raw.data) return (navigate("/cyberintel/instances"))
            setInfo(raw.data)
        }
        fetch()

    }, [loaded])

    const Switch = page?.ref

    function traversal(items) {
        if (!items) return (null)

        return (Object.values(items).map((item) => {
            return ({
                label: item.menu,
                key: item.key,
                children: traversal(item.children)
            })
        }))
    }

    return (
        <DiventryLayoutCyberIntel>
            <DiventryMenu kernel={kernel} />
            <DiventryLayoutHeader
                title={info.name}
                description={page?.description}
            />
            <DiventryLayoutContent>
                <p>
                    <Space>
                        <Button onClick={() => navigate("/cyberintel/instances")}><CaretLeftOutlined /></Button>
                    </Space>
                </p>

                {Switch ?
                    <DiventrySide
                        selectedKeys={[params.visionPage]}
                        menu={traversal(pages)}
                        onClick={(e) => {
                            navigate(`/cyberintel/instances/${params.id}/${e.key}`)
                            setLoaded(!loaded)
                        }}
                    >
                        <Switch kernel={kernel} />
                    </DiventrySide>
                    : null}
            </DiventryLayoutContent>

            <DiventryLayoutFooter kernel={kernel}/>
        </DiventryLayoutCyberIntel>
    );
}