import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
    Button,
    Modal,
    Space,
    Spin,
    Table,
    Tabs,
    Form,
    Input,
    Select,
    Tag,
    notification,
    theme
} from 'antd';

import {
    ReloadOutlined,
    LockOutlined
} from '@ant-design/icons';

import {
    DiventryBlock
} from '../../../../Templates/Layout';


import Brands from '../../Brands/Index'
import { useTranslation, Trans } from "react-i18next";


const deviceTypesAdd = {
    ...Brands.DeviceTypes
}
delete deviceTypesAdd.appleMac
delete deviceTypesAdd.appleiOS
delete deviceTypesAdd.googleAndroid
delete deviceTypesAdd.windows
delete deviceTypesAdd.linux
delete deviceTypesAdd.freeBSD
delete deviceTypesAdd.proxy
// delete deviceTypesAdd.browser

const { useToken } = theme;

export const LogsColors = {
    blocking: "4px solid #a14838",
    detecting: "4px solid #e58900",
    passed: "4px solid #22d933",
    bypassing: "4px solid #4974a5",
}




export function Devices({ kernel }) {
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()

    const [loaded, setLoaded] = useState(true)
    const [loading, setLoading] = useState(false)

    const [profile, setProfile] = useState({})
    const [devices, setDevices] = useState([])

    const [enrollModal, setEnrollModal] = useState(false)
    const [enrollLoading, setEnrollLoading] = useState(false)
    const [activeDeviceTab, setActiveDeviceTab] = useState('enroll-tab-free')

    const [addForm] = Form.useForm()
    const [addModal, setAddModal] = useState(false)
    const { t, i18n, ready } = useTranslation();

    const columns = [
        {
            title: t('Devices.device'),
            dataIndex: 'deviceType',
            key: 'deviceType',
            render: (text, record, index) => {
                return (<Brands.BrandedDevice device={record} />)
            }
        },
        {
            title: t('Devices.lastActivity'),
            dataIndex: 'lastContact',
            key: 'lastContact',
            render: (text, record, index) => {
                if (!text)
                    return ("-")
                return (new Date(text).toLocaleString())
            }
        },

        {
            title: t('Devices.management'),
            dataIndex: 'deviceManagement',
            key: 'deviceManagement',
            render: (text, record, index) => {

                if (text === "mdm")
                    return (<Tag
                        color="red"
                        bordered={false}
                        style={{
                            fontWeight: 700
                        }}>< LockOutlined /> {t('Devices.managed')}</Tag >)

                if (text === "free")
                    return (<Tag
                        color="green"
                        bordered={false}
                        style={{
                            fontWeight: 700
                        }}>< LockOutlined /> {t('Devices.free')}</Tag >)

                return ("-")
            }
        },
        {
            dataIndex: 'id',
            key: 'id',
            align: "right",
            render: (text, record, index) => {
                return (<Space.Compact size="small">
                    <Button disabled={kernel?.subscription?.expiration} type="default" onClick={() => {
                        localStorage.setItem("deviceBack", location.pathname + location.search)
                        navigate(`/dohzel/device/${record.id}/settings`)
                    }}>{t('Devices.settings')}</Button>
                    <Button type="default" onClick={() => {
                        localStorage.setItem("deviceBack", location.pathname + location.search)
                        navigate(`/dohzel/device/${record.id}/logs`)
                    }}>{t('Devices.logs')}</Button>
                    <Button type="default" onClick={() => {
                        localStorage.setItem("deviceBack", location.pathname + location.search)
                        navigate(`/dohzel/device/${record.id}/statistics`)
                    }}>{t('Devices.statistics')}</Button>
                </Space.Compact>)
            }
        },
    ]

    useEffect(() => {
        if (!params.id)
            return

        async function fetchProfile() {
            var current = await kernel.api.get(`/dohzel/profile/get?id=${params.id}`)
            if (current.error) {
                notification.error({
                    message: t('Devices.errorFetchingProfile'),
                    description: current.error
                })
                navigate(`/dohzel/profile`)

                return
            }
            setProfile(current.data)
        }

        async function fetch() {
            setLoading(true)
            var response = await kernel.api.get(`/dohzel/device/list?profileId=${params.id}`)
            if (response.error) {
                notification.error({
                    message: t('Devices.errorGettingDeviceList'),
                    description: response.error
                })
                return
            }
            setLoading(false)
            setDevices(
                response.data?.sort((device1, device2) => {
                  if (!device1?.name) return -1;
                  if (!device2?.name) return 1;
                  return device1.name.toLowerCase()?.trim()?.localeCompare(device2?.name?.trim().toLowerCase());
                })
            );
        }
        fetchProfile()
        fetch()
    }, [params.id, loaded])

    async function enrollOk() {

    }

    async function enrollCancel() {
        setEnrollModal(false)
    }

    async function enrollRefresh() {
        setEnrollLoading(true)
        var response = await kernel.api.get(`/dohzel/profile/changeEnrollCode?id=${params.id}`)
        setEnrollLoading(false)
        if (response.error) {
            notification.error({
                message: t('Devices.errorFetchingProfile'),
                description: response.error
            })
            return
        }
        setLoaded(!loaded)
    }

    async function addOk() {
        const data = {
            ...addForm.getFieldsValue(),
            profileId: params.id
        }

        var response = await kernel.api.post(`/dohzel/device/create`, data)
        if (response.error) {
            notification.error({
                message: t('Devices.errorAddingDevice'),
                description: response.error
            })
            return
        }
        notification.success({
            message: t('Devices.deviceAdded')
        })
        setAddModal(false)
        enrollCancel(false)
        setLoaded(!loaded)
    }

    async function addCancel() {
        setAddModal(false)
    }

    if (ready) {
        return (
            <>
                <Modal
                    title={t('Devices.enrollDevice')}
                    open={enrollModal}
                    onOk={activeDeviceTab == 'enroll-tab-device-add' ? addOk : enrollCancel}
                    onCancel={enrollCancel}
                >
                    <Spin spinning={enrollLoading}>
                        <Tabs onChange={(activeKey) => setActiveDeviceTab(activeKey)} activeKey={activeDeviceTab} defaultActiveKey="1" type="card" items={[
                            {
                                key: 'enroll-tab-free',
                                label: t('Devices.free'),
                                children: <p>
                                    <div style={{ padding: 10 }}>
                                        <center>
                                            <Space>
                                                <div style={{ fontWeight: 800, fontSize: 34 }}>
                                                    {profile.enrollFREE}
                                                </div>
                                                <Button onClick={enrollRefresh}>{!profile.enrollFREE ? t('Devices.generate') : null}<ReloadOutlined /></Button>
                                            </Space>
                                        </center>
                                    </div>

                                    <p>
                                        {t('Devices.deviceFreedomDescription')}
                                    </p>
                                    <ul>
                                        <li>{t('Devices.deviceFreedomList1')}</li>
                                        <li>{t('Devices.deviceFreedomList2')}</li>
                                        <li>{t('Devices.deviceFreedomList3')}</li>
                                        <li>{t('Devices.deviceFreedomList4')}</li>
                                        <li>{t('Devices.deviceFreedomList5')}</li>
                                    </ul>
                                    <p>{t('Devices.useCases')}</p>
                                    <ul>
                                        <li>{t('Devices.deviceFreedomUseCase1')}</li>
                                        <li>{t('Devices.deviceFreedomUseCase2')}</li>
                                    </ul>
                                </p>,
                            },
                            {
                                key: 'enroll-tab-mdm',
                                label: t('Devices.managed'),
                                children: <div>
                                    <div style={{ padding: 10 }}>
                                        <center>
                                            <Space>
                                                <div style={{ fontWeight: 800, fontSize: 34 }}>
                                                    {profile.enrollMDM}
                                                </div>
                                                <Button onClick={enrollRefresh}>{!profile.enrollMDM ? t('Devices.generate') : null}<ReloadOutlined /></Button>
                                            </Space>
                                        </center>
                                    </div>

                                    <p>
                                        {t('Devices.managedEnrollmentDescription')}
                                    </p>
                                    <ul>
                                        <li>{t('Devices.managedUseCase1')}</li>
                                        <li>{t('Devices.managedUseCase2')}</li>
                                        <li>{t('Devices.managedUseCase3')}</li>
                                        <li>{t('Devices.managedUseCase4')}</li>
                                        <li>{t('Devices.managedUseCase5')}</li>
                                    </ul>
                                    <p>{t('Devices.useCases')}</p>
                                    <ul>
                                        <li>{t('Devices.managedUseCase6')}</li>
                                        <li>{t('Devices.managedUseCase7')}</li>
                                        <li>{t('Devices.managedUseCase8')}</li>
                                    </ul>
                                </div>,
                            },
                            {
                                key: 'enroll-tab-device-add',
                                label: t('Devices.deviceDirectlyAdded'),
                                children: <div>
                                    <h2 style={{marginTop: 30, marginBottom: 40, textAlign: 'center'}}>
                                        {t('Devices.addDeviceDescription')}
                                    </h2>

                                    <Form
                                        form={addForm}
                                    >
                                        <Form.Item label={t('Devices.deviceName')} name="name">
                                            <Input />
                                        </Form.Item>

                                        <Form.Item label={t('Devices.deviceType')} name="deviceType">
                                            <Select
                                                style={{ width: '100%' }}
                                                options={Object.keys(deviceTypesAdd).map((item) => {
                                                    return ({
                                                        value: item,
                                                        label: <Brands.BrandedDeviceLine type={item} />
                                                    })
                                                })}
                                            />
                                        </Form.Item>
                                    </Form>
                                </div>,
                            }
                        ]} />

                        <small>
                            {t('Devices.deviceFreedomExplanation')}
                        </small>
                    </Spin>
                </Modal>

                <Modal
                    title={t('Devices.addDevice')}
                    open={addModal}
                    onOk={addOk}
                    onCancel={addCancel}
                >
                    <p>
                        {t('Devices.addDeviceDescription')}
                    </p>

                    <Form
                        form={addForm}
                    >
                        <Form.Item label={t('Devices.deviceName')} name="name">
                            <Input />
                        </Form.Item>

                        <Form.Item label={t('Devices.deviceType')} name="deviceType">
                            <Select
                                style={{ width: '100%' }}
                                options={Object.keys(deviceTypesAdd).map((item) => {
                                    return ({
                                        value: item,
                                        label: <Brands.BrandedDeviceLine type={item} />
                                    })
                                })}
                            />
                        </Form.Item>
                    </Form>
                </Modal>

                <p style={{marginTop: 10}}>
                    {/* <Button type="primary" onClick={() => setEnrollModal(true)}>{t('Devices.enrollDevice')}</Button>
                    <Button type="primary" onClick={() => setAddModal(true)} style={{marginLeft: 5}}>{t('Devices.addDevice')}</Button> */}
                    <Button type="primary" onClick={() => setEnrollModal(true)}>{t('Devices.addDevice')}</Button>
                </p>

                <Table
                    pagination={{ position: ['topLeft', 'bottomRight'] }}
                    ellipsis={true}
                    loading={loading}
                    showSizeChanger={true}
                    dataSource={devices}
                    columns={columns}
                />
            </>
        )
    }
    return <span>{t('Loading')}</span>;
}
